<template>
  <div class="footerStyle">
    <div class="color_1 copyright">
      Copyright @2022 YWDX 杭州氧屋东西科技有限公司
    </div>
    <div class="color_1 record">
      <!-- <p class="policeStyle"></p> -->
      备案号：
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        rel="noopener noreferrer"
        >浙ICP备2020043708号-1</a
      >
    </div>
    <div class="color_1 law">法律条款</div>
    <div class="color_1 contact">联系我们</div>
    <div class="color_1 privacy">隐私政策</div>
    <div class="color_1 recruit">校园招聘</div>
    <div class="color_2 download">
      <a
        href="https://3d.h5.zhaidoudou.com/pro/editor/OxiRenderSetup.exe"
        rel="noopener noreferrer"
        >下载 OXI RENDER （提供各种版本下载）</a
      >
    </div>
    <!-- <div class="color_2 edition_1">所有版本</div>
    <div class="color_2 edition_2">LTS版本</div>
    <div class="color_2 edition_3">补丁版本</div> -->
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
.footerStyle {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 200px;
  background-color: #000000;
  font-size: 16px;
  font-family: 'SimSun';
  font-weight: 400;
}
.color_1 {
  color: #999999;
}
.color_2 {
  color: #7fe96e;
}
.copyright {
  position: absolute;
  top: 60px;
  left: 404px;
}
.record {
  position: absolute;
  top: 94px;
  left: 404px;
  line-height: 20px;
}
.policeStyle {
  display: inline-block;
  margin: 0 5px;
  width: 14px;
  height: 16px;
  background: url('/img/police.png');
  background-size: contain;
}
.law {
  position: absolute;
  top: 59px;
  left: 948px;
}
.contact {
  position: absolute;
  top: 94px;
  left: 948px;
}
.privacy {
  position: absolute;
  top: 60px;
  left: 1099px;
}
.recruit {
  position: absolute;
  top: 95px;
  left: 1099px;
}
.download {
  position: absolute;
  top: 60px;
  left: 1249px;
}
.edition_1 {
  position: absolute;
  top: 95px;
  left: 1253px;
}
.edition_2 {
  position: absolute;
  top: 95px;
  left: 1332px;
}
.edition_3 {
  position: absolute;
  top: 95px;
  left: 1405px;
}

.record a {
  text-decoration: none;
  color: #999999;
}
.download a {
  text-decoration: none;
  color: #7fe96e;
}
a:hover {
  color: rgb(255, 255, 255);
}
</style>
